/* src/TermosDeUso.css */
.termos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    max-width: 500px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .termos-form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .checkbox-group {
    display: flex;
    align-items: center;
  }
  
  .checkbox-input {
    margin-right: 10px;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .submit-button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  
  .error-text {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .spinner {
    margin: 20px auto;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top-color: #e74c3c;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }